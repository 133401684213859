$thumbnail-size: 250px;
$thumbnail-height: 400px;
$product-description-height: 70px;

.products {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    --bs-gutter-x: 20px;
}

.product {
    // display: flex;
    // justify-content: center;
    margin-bottom: 1.25rem;
}

.product-thumbnail {
    display: block;
}

.products-section-title {
    margin: 2.5rem 0;
    font-weight: 500;
    text-align: center;
}

.price {
    color: $primary;
    font-weight: 600;
}

.regular-price {
    display: inline-block;
    font-weight: 600;
    // color: $silver;
    color: $shades-grey;
    text-decoration: line-through;

}

.swiper-slide {
    .product-miniature {
        img {
            width: 100%;
            object-fit: cover;
        }
    }
}

.product-miniature {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    height: 100%;

    .second-img {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 100%;
        overflow: hidden;
        opacity: 0;
        transition: all .5s;
        transform: translateY(-100%);
    }

    .product-description {
        padding: 1.875rem;
    }

    .product-cat {
        a {
            color: $shades-grey;
            text-decoration: none;
            font-size: 12px;
        }
    }

    .product-title {
        margin-top: 0.5rem;
        min-height: 2.5em;

        a {
            font-size: .938rem;
            font-weight: 600;
            color: $black;
            text-decoration: none;
        }
    }

    .price {
        font-size: 15px;
    }

    .regular-price {
        font-size: 12px;

        &:before {
            content: "/";
            display: inline-block;
            margin: 0 .25rem;
            color: $light-grey;
            // font-size: 30px;
        }
    }

    .thumbnail-container {
        position: relative;
        height: auto;
        // overflow: hidden;
        background: $white;
        height: 100%;

        &:hover,
        &:focus {
            .second-img {
                display: block;
                transform: translateY(0);
                opacity: 1;
            }

            .quick-view {
                opacity: 1;
            }

            .product-description::after {
                position: absolute;
                top: 0;
                left: 10%;
                width: 80%;
                content: "";
                // border-top: $grey 1px solid;
                opacity: 0.25;
            }
        }

        .product-thumbnail {
            position: relative;
            // height: calc(100% - #{$product-description-height});
            height: 185px;

            img {
                position: relative;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                max-width: 100%;
                max-height: 100%;
                margin: auto;
                height: 185px;
                width: 100%;
                object-fit: cover;
            }
        }
    }


    .quick-view {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 12px;
        letter-spacing: 0.025em;
        color: $davys-grey;
        background: $white;
        display: flex;
        align-items: center;
        text-decoration: none;
        padding: 0.375rem 0.75rem;
        border-radius: 3px 0px 0px 0px;
        transition: all .5s;
        opacity: 0;

        &:hover {
            color: $primary;
        }
    }


    .wishlist-button-add {
        position: absolute;
        top: .5rem;
        right: .5rem;
        z-index: 10;
    }

    .product-price-and-shipping {
        margin-top: 1.5rem;
    }

}

.product-icons {
    margin-top: 1.5rem;

    a {
        width: 28px;
        height: 28px;
        display: inline-block;

        +a {
            margin-left: 1rem;
        }

        svg {
            path {
                fill: $shades-grey;
            }
        }

        @include hover-focus() {
            svg {
                path {
                    fill: $primary;
                }
            }
        }
    }
}

.product-flags {
    padding-left: 0;
    list-style: none;
    position: absolute;
    top: 0;
    left: -.25rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // width: 100%;
    pointer-events: none;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;

    .product-flag {
        width: fit-content;
        padding: 0 0.875rem;
        padding-right: 0;
        margin-top: .375rem;
        font-weight: 600;
        color: $lighter-orange;
        pointer-events: auto;
        background: $primary;
        display: inline-flex;
        align-items: center;
        // border-radius: 25px;
        border-top-left-radius: 2px;
        position: relative;
        height: 26px;

        >*:first-child {
            margin-right: .5rem;
        }

        &:after {
            content: "";
            // float: right;
            position: absolute;
            top: 0;
            left: 100%;
            // margin-right: -11px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 13px 0 13px 11px;
            border-color: transparent transparent transparent $primary;
        }

        &:before {
            content: "";
            // float: right;
            position: absolute;
            bottom: -4px;
            left: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 4px 4px 0;
            border-color: transparent darken($primary, 10%) transparent transparent;
        }

        // .arrow {
        //     position: absolute;
        //     top: 0;
        //     left: 100%;
        //     width: 0;
        //     height: 0;
        //     border-style: solid;
        //     border-width: 13px 0 13px 11px;
        //     border-color: transparent transparent transparent blue;
        // }
        span[class^="material"] {
            font-size: 1rem;
        }


        // &.online-only {
        //     position: absolute;
        //     top: 25rem;
        //     right: 0;
        //     z-index: 1;
        //     margin-top: 0;
        //     font-size: $font-size-sm;

        //     &::before {
        //         margin: 0.3125rem;
        //         font-family: "Material Icons", Arial, sans-serif;
        //         vertical-align: middle;
        //         content: "\E30A";
        //     }
        // }
        &--text {
            font-size: 12px;
            opacity: 0;
            display: none;
            transition: all 300ms ease-in-out 0s;
            padding-right: .5rem;
        }

        &.delivery,
        &.discount-percentage,
        &.discount-amount,
        &.discount {
            background-color: $success;

            &:after {
                border-color: transparent transparent transparent $success;
            }

            &:before {
                border-color: transparent darken($success, 10%) transparent transparent;
            }
        }

        &.new {
            background-color: $danger;

            &:after {
                border-color: transparent transparent transparent $danger;
            }

            &:before {
                border-color: transparent darken($danger, 10%) transparent transparent;
            }
        }

        @include hover-focus {
            cursor: pointer;

            .product-flag--text {
                opacity: 1;
                display: inline-block;
            }
        }

    }
}


.wishlist-button-add {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    min-width: 30px;
    padding-top: 3px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    cursor: pointer;
    transition: 0.2s ease-out;
    border: none;
    color: $grey;

    i {
        font-size: 1rem;

        &:after {
            content: "\e87e";
            font-size: 1rem;
            transition: all .3s;
        }

        @include hover-focus() {
            color: $primary;
        }
    }

    &.active {
        background-color: $primary;

        i {
            color: #fff;

            &:after {
                content: "\e87d";
            }
        }
    }


}

.wishlist-button-product {
    background-color: $cultured;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    height: 46px;
    width: 46px;
    min-width: 46px;

    i {
        &:after {
            font-size: 24px;
        }
    }
    @include media-breakpoint-down(lg) {
        position: absolute;
        right: 20px;
        bottom: calc(100% + 1.5rem);
        top: initial;
        transform: none;
    }
}

.modal-body {
    .product-icons {
        margin-top: 1rem;
        margin-bottom: 1.5rem;

        svg,
        a {
            width: 40px;
            height: 40px;
        }
    }

    .product-price {
        font-size: .875rem;
    }

    .current-price {
        font-size: 1.25rem;
        font-weight: 600;
    }

}