.cart-summary-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .75rem 0;

    .label {
        font-weight: 600;
        color: #000;
    }
}

.cart-summary-totals {
    @include media-breakpoint-up(md) {
        border-top: 1px solid $light-grey;
    }

    margin-top: 1rem;

    .value {
        color: $primary;
        font-size: 24px;
    }
}

.cart-detailed-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .75rem;
    // padding: 1rem 0;
}

.step-edit {
    color: $shades-grey;
    font-weight: normal;
    display: flex;
    align-items: center;
}

.cart-detailed-actions {
    margin-top: 1.5rem;
}

.cart-item-name {
    font-weight: 600;
    color: #000;
    margin-bottom: 1rem;
    display: block;
    max-width: 80%;
}

.product-line-info {
    display: inline-flex;
    font-size: .875rem;
    margin-bottom: .5rem;

    // &:not(:last-child) {
        margin-right: 1.5rem;
    // }

    .value {
        margin-left: .25rem;
    }
}

.cart-items {
    @include media-breakpoint-up(lg) {
        border-right: 1px solid $light-grey;
        padding-right: 1rem;
    }
}

.cart-item {
    position: relative;
    padding-bottom: 3rem;

    @include media-breakpoint-up(md) {
        min-height: 205px;
    }

    &:not(:first-child) {
        margin-top: 3rem;
    }

    &:not(:last-child) {
        border-bottom: 1px solid $light-grey;
    }

    @include media-breakpoint-down(md) {
        margin-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
    .calc-total-val {
        font-weight: 400;
    }
}

.remove-from-cart {
    position: absolute;
    top: 0;
    right: 0;
    color: $shades-grey;
}

.product-line-grid-left {
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
        position: absolute;
    }
}
.cart-detailed-totals,
.cart-overview {
    @include media-breakpoint-up(lg) {
       margin-top: 3rem;
    }
}

// calculator 

.bootstrap-touchspin .btn {
    padding: 0.375rem 0.5rem;
    font-size: 1.25rem;
    line-height: .5;
    width: 2.25rem;
    height: 2.25rem;
}
.quantity_wanted {
    max-width: 60px;
}

.calculator {
    color: $shades-grey;

    [class*="col"] {
        margin-bottom: 1rem;
    }

    .control-label {
        display: block;
        margin-bottom: .5rem;
    }

    .form-control {
        background: $light-grey;
        padding: .25rem 1rem;
        text-align: center;
        border-radius: 3px;
        height: 36px;
    }

    .form-switch {
        display: flex;
        align-items: center;

        label {
            margin-left: .5rem;
            font-size: .75rem;
            margin-top: 3px;
            // font-weight: 600;
        }

    }
    
}

.sticky-mobile {
    @include media-breakpoint-down(md) {
        background-color: $lighter-orange;
        padding: 1rem;
        position: sticky;
        bottom: 0;
        z-index: 1020;
        margin-right: -1.25rem;
        margin-left: -1.25rem;
    }
}