.main-footer {
    background-color: $floral-white;
    padding-top: 5.625rem;
    margin-top: 2rem;
    color: $shades-grey;
    font-size: .938rem;

    img {
        margin-bottom: 1rem;
        max-width: 70px;

    }

    picture {
        +picture {
            margin-left: 1rem;
        }
    }

    a {
        color: $shades-grey;
        text-decoration: none;

        @include hover-focus() {
            text-decoration: underline;
        }
    }

    p {
        margin-bottom: .25rem;
    }

    h3 {
        font-weight: 500;
        margin-bottom: 2rem;
        font-family: 'Roboto';
    }

    .footer-logo {
        max-width: 160px;
        svg {
            margin-bottom: 1rem;
        }
    }

    .nav {
        margin-left: -1rem;
        font-family: 'Roboto';

        &.flex-column {
            margin-bottom: 2.125rem;
        }
    }

    .nav-link {
        color: $shades-grey;
        font-size: .938rem;
        padding: .235rem 1rem;

        @include hover-focus() {
            text-decoration: underline;
        }
    }

}

.copyright__block {
    border-top: 1px solid $light-grey;
    padding: 1rem 0;
    margin-top: 2rem;

    .nav-link {
        font-size: .75rem;

    }

    @include media-breakpoint-down(md) {
        .nav-item {
            width: 100%;
        }
    }
}

.nav-signature {
    @include media-breakpoint-up(md) {
        justify-content: flex-end;
    }

    .nav-item {
        display: flex;
        font-size: .75rem;
        align-items: center;
        margin-left: 1rem;
    }

    .nav-link {
        font-weight: 700;
        padding: .235rem 0;
    }
}

.social__block {
    margin-left: -.5rem;
    margin-right: 1rem;

    svg {
        path {
            fill: $shades-grey;
        }

        @include hover-focus() {
            path {
                fill: $primary;
            }
        }
    }
    .nav-link {
        width: 30px;
        min-width: 30px;
        height: 30px;
        // line-height: 30px;
        line-height: 1.7;
        padding: 0;
        text-align: center;
        // background-color: $light-grey;
        // border-radius: 50%;
    }
}
