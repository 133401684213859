// .checkout-step {
//     box-shadow: none;
//     border: 0;
//     padding: 0;
//     outline: none;
//     background: none;
//     .step-title {
//         cursor: pointer;
//         margin-bottom: 0;
//         position: absolute;
//         top: 0;
//         font-size: 15px;
//         line-height: 20px;
//         width: 25%;
//         text-align: center;
//         padding: 30px 5px;
//         color: $shades-grey;


//     }
// }
// .checkout-page {
//     position: relative;
//     padding-top: 100px;
// }

.checkout-steps {
    justify-content: space-around;

    margin-bottom: 1.5rem;
    flex-wrap: nowrap;
    overflow-x: auto;

    @include media-breakpoint-up(lg) {
        border-bottom: 1px solid $secondary;
    }

    @include media-breakpoint-down(lg) {
        justify-content: flex-start;
    }


    .nav-link {
        color: $shades-grey;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        padding: 1rem 1rem;
        border-bottom: 1px solid transparent;
        white-space: nowrap;

        &.active {
            color: $primary;

            @include media-breakpoint-up(lg) {
                border-bottom: 1px solid $primary;
            }

        }

        &.complete {
            .material-icons {
                display: inline-block;
            }
        }
    }

    .material-icons {
        font-size: 1rem;
        display: none;
    }

}

.checkout-step {
    .content {
        @include media-breakpoint-up(xl) {
            padding: 2rem 5rem 0;
        }
    }
}

.delivery-options {
    margin-bottom: 2rem;

    p {
        margin-bottom: 0;
    }

    .form-check {
        min-height: 110px;
        display: flex;
        align-items: center;
        padding-left: 1rem;
        padding-right: 1rem;
        border: 2px solid $light-grey;
        border-radius: 2px;
        margin-bottom: 1rem;

        &.selected {
            border-color: $primary;

            // img {
            //     filter: grayscale(0);
            // }

        }

        // &:not(.selected) {
        //     img {
        //         filter: grayscale(1);
        //     }
        // }
    }

    .form-check-input {
        // min-width: 1em;
        // margin-right: 1rem;
        // margin-left: 0;
        display: none;
    }

    // .row {
    //     align-items: center;
    // }
    .form-check-label {
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 100%;
        @include hover-focus() {
            cursor: pointer;
        }
    }

    img {
        transition: all .3s;
        margin: 1rem 0;
        filter: grayscale(1);
    }

    h4 {
        line-height: 1.5;
    }
}

.form-heading {
    font-size: 1.125rem;
    margin-bottom: 1.5rem;
    color: #000;
    font-weight: 400;
}

.pickup-check {
    display: block;
    min-height: $form-check-min-height;
    padding-left: $form-check-padding-start;
    margin-bottom: $form-check-margin-bottom;

    .pickup-check-input {
        float: left;
        margin-left: $form-check-padding-start * -1;
    }
}

.pickup-check-label {
    font-size: .875rem;

    div {
        font-size: .813rem;
        display: none;
    }

    .text-primary {
        font-size: .75rem;
    }
}

.pickup-check-input {
    width: $form-check-input-width;
    height: $form-check-input-width;
    margin-top: ($line-height-base - $form-check-input-width) / 2; // line-height minus check height
    vertical-align: top;
    background-color: $form-check-input-bg;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: $form-check-input-border;
    appearance: none;
    color-adjust: exact; // Keep themed appearance for print
    @include transition($form-check-transition);

    &[type="checkbox"] {
        @include border-radius($form-check-input-border-radius);
    }

    &[type="radio"] {
        // stylelint-disable-next-line property-disallowed-list
        border-radius: $form-check-radio-border-radius;
    }

    &:active {
        filter: $form-check-input-active-filter;
    }

    &:focus {
        border-color: $form-check-input-focus-border;
        outline: 0;
        box-shadow: $form-check-input-focus-box-shadow;
    }

    &:checked {
        background-color: $form-check-input-checked-bg-color;
        border-color: $form-check-input-checked-border-color;

        ~.pickup-check-label {
            div {
                display: block;
            }
        }

        //   &[type="checkbox"] {
        //     @if $enable-gradients {
        //       background-image: escape-svg($form-check-input-checked-bg-image), var(--#{$variable-prefix}gradient);
        //     } @else {
        //       background-image: escape-svg($form-check-input-checked-bg-image);
        //     }
        //   }

        &[type="radio"] {
            @if $enable-gradients {
                background-image: escape-svg($form-check-radio-checked-bg-image),
                var(--#{$variable-prefix}gradient);
            }

            @else {
                background-image: escape-svg($form-check-radio-checked-bg-image);
            }

        }

        &[type="checkbox"]:indeterminate {
            background-color: $form-check-input-indeterminate-bg-color;
            border-color: $form-check-input-indeterminate-border-color;

            @if $enable-gradients {
                background-image: escape-svg($form-check-input-indeterminate-bg-image),
                var(--#{$variable-prefix}gradient);
            }

            @else {
                background-image: escape-svg($form-check-input-indeterminate-bg-image);
            }
        }

        &:disabled {
            pointer-events: none;
            filter: none;
            opacity: $form-check-input-disabled-opacity;
        }

        // Use disabled attribute in addition of :disabled pseudo-class
        // See: https://github.com/twbs/bootstrap/issues/28247
        &[disabled],
        &:disabled {
            ~.form-check-label {
                opacity: $form-check-label-disabled-opacity;
            }
        }
    }
}


.payment-option {
    .form-check {
        min-height: 110px;

        @include media-breakpoint-up(lg) {
            margin: 0 1.5rem;
        }

    }

    .form-check-label {
        width: 100%;
        padding-left: 1rem;

        img {
            @extend .img-fluid;
        }

    }
}

.paysera-options {
    .col-4 {
        margin: 1rem 0;
        text-align: center;
    }
}

.checkout-page {
    @include media-breakpoint-up(lg) {
        border-right: 1px solid $light-grey;
    }
}

.checkout-right {
    h2 {
        padding-top: 1rem;
        color: #000000;
    }
}

.form-check-label {
    a {
        color: $body-color;
        text-decoration: underline;
    }
}

[name="paysera"] {
    position: absolute;
    opacity: 0;

    +img {
        cursor: pointer;
        padding: 1rem;
    }

    &:checked {
        +img {
            outline: 2px solid $primary;
        }
    }
}

.address-option {
    display: flex;
    justify-content: space-between;
}

.address-actions {
    a {
        text-decoration: none;
        //    @extend .btn;
        display: inline-flex;
        align-items: center;
        padding: 0 1rem;
    }

    [class^="material-icons"] {
        font-size: 1rem;
    }
}

.address-options {
    .form-check-label {
        width: 100%;
    }
}

.address-options {
    [name="radioAddress"] {
        &:checked {
            +.form-check-label {
               
                >.collapse {
                   display: block;
                }
            }
        }
    }
}

.heading-address {
    color: #000;
    font-weight: 600;
}