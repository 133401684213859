.product-images {
    padding-left: 0;

    >li.thumb-container {
        display: inline-block;
        margin-bottom: .5rem;
        margin-right: 0.5rem;
        // border: 3px solid transparent;
        // &.selected,
        // &:hover {
        //     border: $primary 3px solid;
        // }

        >.thumb {
            object-fit: cover;
            cursor: pointer;
        }
    }
}

.quick-thumbs,
.cover-thumbs {
    .thumb {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
    }
}


.images-container {
    @include media-breakpoint-down(md) {
        margin-right: calc(var(--bs-gutter-x) / -2);
        margin-left: calc(var(--bs-gutter-x) / -2);
    }

    // .quick-product-images,
    // .qv-product-images {
    //     overflow-x: auto;
    //     white-space: nowrap;

    //     &.scroll {
    //         width: calc(100% - 60px);
    //         margin: 0 auto;
    //     }
    // }
}

.product-cover {
    position: relative;
    margin-bottom: .5rem;

    img {
        background: $white;
    }

    .layer {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        text-align: center;
        cursor: pointer;
        background: $black;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
        //    @include transition(opacity 0.7s ease-in-out);

        &:hover {
            opacity: 1;
        }

        .zoom-in {
            font-size: 3.125rem;
            color: $primary;
        }
    }

    .disclaimer {
        position: absolute;
        bottom: .5rem;
        left: .5rem;
        color: #fff;
        z-index: 5;
    }
}

.scroll-box-arrows {
    display: none;

    &.scroll {
        display: block;
    }

    i {
        position: absolute;
        bottom: 1.625rem;
        height: 100px;
        line-height: 100px;
        cursor: pointer;
    }

    .left {
        left: 0;
    }

    .right {
        right: 0;
    }
}

.product-container {
    .product-flags {
        @include media-breakpoint-down(md) {

            position: relative;
            flex-direction: row;

            .product-flag--text {
                opacity: 1;
                display: block;
            }

            .product-flag {
                border-bottom-left-radius: 2px;
                margin-right: 1rem;

                &:before {
                    display: none;
                }
            }

        }

    }

    #content {
        position: relative;
    }

    h1 {
        font-weight: 600;
        font-size: 20px;
        line-height: 1.5;
        color: $davys-grey;
        margin-bottom: .5em;
    }

    .product-icons {
        margin-top: .5rem;
        margin-bottom: 1.5rem;

        svg,
        a {
            width: 36px;
            height: 36px;
        }

        svg {
            path {
                fill: $shades-grey;
            }
        }
    }

    .product-information {
        font-size: 14px;
    }

    .product-title-mobile {
        font-size: 18px;
    }
}

.product-reference {
    margin-bottom: 1.5rem;
    color: $shades-grey;
    font-size: .75rem;
}

.product-prices {
    border-top: 1px solid $cultured;
    border-bottom: 1px solid $cultured;
    padding: 1rem 0;

    .row {
        align-items: flex-end;
    }

    .current-price-value {
        font-size: 20px;
        color: $success;
        font-weight: 600;
        padding-right: .5rem;
    }

    .regular-price {
        font-weight: 400;
        font-size: .75rem;

    }

    .product-pack {
        .current-price-value {
            color: $primary;
            font-size: 1rem;
        }
    }
}

.product-size-value {
    font-size: .875rem;
    font-weight: 600;
    color: #000;
}

.product-variants {
    .product-variants-item {
        margin-bottom: 1.5rem;

        .form-select {
            border: 1px solid $light-grey;
            padding: .5rem 1rem;
            height: calc(2.25rem + 4px);
            font-weight: 600;

            ~label {
                // background-color: #fff;
                font-weight: 600;
            }

            // @include media-breakpoint-up(md) {
            //     max-width: 80%
            // }
        }
    }

    ul {
        @extend .list-unstyled;
    }
}

.product-add-to-cart {
    color: $shades-grey;

    .add-to-cart {
        width: 100%;
    }
}

.footage input {
    display: inline-block;
    width: inherit;
}

.calc-total-val {
    font-weight: 600;
    color: $primary;
    font-size: 18px;
}

.product-add-to-cart {
    .calc-total-val {
        font-size: 24px;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.bootstrap-touchspin {
    input {
        margin-left: .35rem !important;
        margin-right: .35rem;
        border-radius: 3px !important;
    }
}

.product-feature-icons {
    border-top: 1px solid $light-grey;
    padding-top: 1rem;
    margin-top: 5rem;

    .card {
        box-shadow: none;
        text-align: center;
        color: $shades-grey;
        margin: 1rem 0;
    }

    .card-title {
        font-weight: normal;
        font-size: 18px;
    }

    .card-text {
        font-weight: normal;
        font-size: 14px;
    }

    .card-img {
        height: 50px;
    }

    .card-body {
        padding: 1rem;
    }
}

.data-sheet {
    border-top: 1px solid $light-grey;
    color: $shades-grey;
    font-size: 14px;

    .row {
        border-bottom: 1px solid $light-grey;
        margin-right: 0;
        margin-left: 0;
    }

    .name {
        font-weight: 600;
        padding: 1rem 0;
    }

    .value {
        text-align: right;
        padding: 1rem 0;
    }
}

.attachment {
    margin-bottom: 2.5rem;

    h4 {
        a {
            color: $shades-grey;
        }
    }

    .btn {
        color: $primary;
        padding: 0.375rem 0;

        @include hover-focus() {
            color: darken($primary, 10%);
        }
    }
}

.extra-delivery {
    align-items: center;
    border-bottom: 1px solid $light-grey;
    padding: 1rem 0;

    svg+svg {
        margin-left: 1.5rem;
    }

    p {
        margin-bottom: 0;
    }

    .h4 {
        margin-bottom: 1rem;
    }

    .col-md-4 {
        @include media-breakpoint-down(md) {
            margin-bottom: 1.5rem;
        }
    }

}

.extra-delivery-title {
    margin-bottom: 0;
    padding: 1rem 0;
    color: #fff;
    font-size: 15px;
}

.swiper-product-quick,
.swiper-product-cover {
    .swiper-slide {
        height: auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.cover-next,
.cover-prev {
    color: #fff;

    &:after {
        font-size: 24px;
    }
}

.section-accessories {
    margin-left: calc((100vw - 100%) / -2);
    width: 100vw;
    @include media-breakpoint-up(md) {
        background-color: $lighter-orange;
    }
}

.qty {
    .btn {
        @include hover-focus() {
            color: #fff;
        }
    }
}

.info-detail {
    margin-bottom: 1.5rem;

    strong {
        color: #000;
        font-size: 1rem;
    }
}

.product-extra {
    @include media-breakpoint-only(md) {
        flex-direction: row-reverse;
    }
}
