/* open-sans-regular - latin-ext_latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/open-sans-v27-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/open-sans-v27-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/open-sans-v27-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/open-sans-v27-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/open-sans-v27-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/open-sans-v27-latin-ext_latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-600 - latin-ext_latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/open-sans-v27-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/open-sans-v27-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/open-sans-v27-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/open-sans-v27-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
         url('../fonts/open-sans-v27-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/open-sans-v27-latin-ext_latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-italic - latin-ext_latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/open-sans-v27-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/open-sans-v27-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/open-sans-v27-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/open-sans-v27-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/open-sans-v27-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/open-sans-v27-latin-ext_latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
  }
  /* open-sans-600italic - latin-ext_latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: url('../fonts/open-sans-v27-latin-ext_latin-600italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/open-sans-v27-latin-ext_latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/open-sans-v27-latin-ext_latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/open-sans-v27-latin-ext_latin-600italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/open-sans-v27-latin-ext_latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/open-sans-v27-latin-ext_latin-600italic.svg#OpenSans') format('svg'); /* Legacy iOS */
  }

  /* roboto-regular - latin-ext_latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-v29-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/roboto-v29-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v29-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v29-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v29-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v29-latin-ext_latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-italic - latin-ext_latin */
  @font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/roboto-v29-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/roboto-v29-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v29-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v29-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v29-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v29-latin-ext_latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-500 - latin-ext_latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/roboto-v29-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/roboto-v29-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v29-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v29-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v29-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v29-latin-ext_latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-700 - latin-ext_latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/roboto-v29-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/roboto-v29-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v29-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v29-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v29-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v29-latin-ext_latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
  }