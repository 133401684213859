@import "fonts";
@import "theme-variables";
@import "mixins";
@import "swiper/swiper-bundle";
@import "bootstrap/jquery.bootstrap-touchspin";
@import "material/material-icons";
@import "node_modules/bootstrap/scss/bootstrap";
@import "footer";
@import "header";
@import "product";
@import "product-list";
@import "category";
@import "filters";
@import "cms";
@import "blog";
@import "cart";
@import "checkout";
@import "account";
@import "nouislider/nouislider.scss";

:root {
    --swiper-pagination-color: #3AA11A;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px #ffffff inset;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

// body {
//     padding-top: 56px;
// }

.footer {
    margin-top: 3rem;
}

// .navbar {
//     border-bottom: 1px solid #eee;
// }

.heading-small {
    font-size: 1.125rem;
    color: $shades-grey;
    margin-bottom: 1em;
}

.section-title {
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: 1em;
    color: #000;

    @include media-breakpoint-down(md) {
        font-size: 18px;
    }
}

.section-spacer {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.section-spacer-lg {
    padding-top: 5rem;
    padding-bottom: 5rem;

    @include media-breakpoint-down(md) {
        // [class*="col-md"] {
        //     &:not(:last-child) {
        //         margin-bottom: 5rem;
        //     }
        // }
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
}

.bg-floral-white {
    background-color: $floral-white;
    color: $text-muted;

    h2,
    h3 {
        color: $black;
    }
}

.bg-lighter-orange {
    background-color: $lighter-orange;
    color: $shades-grey;

    h2,
    h3 {
        color: $shades-grey;
    }
}

.bg-black {
    background-color: #000;
}

.text-black {
    color: #000;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-shadow {
    width: calc(100% + 28px); //we need more width for the shadows on the edges
    padding: 14px; //space for the shadows
    margin-left: -14px; //put the div in its original position
    overflow: hidden; //hide the overflow
}

.swiper-slide {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    height: auto;

    .product {
        height: 100%;
    }

    img {
        max-width: 100%;
        height: auto;
    }
}

.swiper-top-nav {
    display: flex;
}

.swiper-top {
    display: flex;
    justify-content: space-between;
    // margin-top: 24px;
    align-items: center;
    padding-bottom: 1rem;

    .section-title {
        margin-bottom: 0;
    }

    margin-right: -1rem;
}

.swiper-prev,
.swiper-next {
    padding: 1rem;
    transition: all .3s;

}

.swiper-prev {
    @include hover-focus() {
        transform: translateX(-5px);
    }
}

.swiper-next {
    @include hover-focus() {
        transform: translateX(5px);
    }
}

.swiper-brands {
    padding: 1rem 0 4rem;

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        transition: all .3s;
        filter: grayscale(1);

        @include hover-focus() {
            filter: grayscale(0);
        }
    }
}

.block-title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 1em;
}

.btn-primary {
    color: $white;
}

.btn-send {
    &:after {
        content: "\e163";
        font-size: 1rem;
        // vertical-align: -0.255em;
        transition: all .3s;
        transform: rotate(-45deg);
        margin-left: .5rem;
        color: $white;
        @extend .material-icons;
    }
}


.btn-right {
    &:after {
        content: "\e315";
        font-size: 1rem;
        vertical-align: -0.255em;
        transition: all .3s;
        // transform: rotate(-45deg);
        margin-left: .5rem;
        color: $white;
        @extend .material-icons;
    }
}

.btn-xl {
    width: 100%;
    min-height: 56px;
}

.btn-block {
    display: block;
    width: 100%;
    text-align: center;
}

.btn-success {
    @include hover-focus() {
        color: #fff;
    }
}

.btn-icon {
    display: flex;
    align-items: center;

    // justify-content: center;
    span {
        margin-right: .5rem;
    }
}

.btn-custom-file {
    color: $dim-grey;
    font-weight: 400;

    &.btn-icon {
        display: inline-flex;
        justify-content: center;

        @include media-breakpoint-up(lg) {
            width: initial;
        }
    }
}

.btn-success {
    color: $white;
}

.btn-tertiary {
    font-weight: normal;
    font-size: 12px;
}

.btn-transparent {
    color: #A4A4A4;
}

.alert {
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0;

    i,
    span {
        vertical-align: middle;
    }
    &.bg-lighter-orange {
        border: 1px solid $warning;
    }
}

a {
    text-decoration: none;
}

.border-right-lg {
    @include media-breakpoint-up(lg) {
        border-right: 1px solid $light-grey;
    }
}

.hero-banner {
    h1 {
        line-height: 1.5;
    }
}

.block-banner {
    background-color: $primary;
}

.hero-banner-item {
    display: flex;
    flex-wrap: wrap;

    // @include media-breakpoint-down(md) {
        height: 100%;
    // }
}

.hero-banner {

    .swiper-hero-prev,
    .swiper-button-next {
        color: #fff;

        &:after {
            font-size: 24px;
        }

    }

    .swiper-pagination-bullet {
        background: #fff;
    }

    @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
    }
}

.banner-content {
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    @include media-breakpoint-down(md) {
        width: 100%;
    }

    h2 {
        font-weight: 600;
        font-size: 30px;
        line-height: 1.25;
        margin-bottom: 1em;
    }

    .btn {
        min-width: 300px;
        min-height: 56px;
        // line-height: 40px;
        font-weight: 600;

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
}

.banner-desc {
    margin-bottom: 2rem;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Roboto';
}

.play-button {
    &.active {
        .pause-icon {
            display: block;
        }
        .play-icon {
            display: none;
        }
    }
    .pause-icon {
        display: none;
        opacity: 0;
    }
    .play-icon {
        display: block;
    }
}
.hero-banner-item--video  {
@include hover-focus() {
    .pause-icon {
        opacity: 1;
    }
}
}


.hero-banner-item--video {
    @include media-breakpoint-up(md) {
        max-height: 463px;
    }

    display: block;
    height: 100%;
    position: relative;

    @include overlay();

    @include media-breakpoint-up(md) {

        color: white;

        .wigrum-title a {
            color: white;
            font-weight: 100;
        }
    }

    .hero-video-controls span {
        color: white;
        font-size: 80px;

        @include hover-focus() {
            outline: none;
        }
    }

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .hero-video-controls {
        position: absolute;
        @include absolute-center;
        z-index: 5;

        button {
            background-color: transparent;
            border: none;

            img {
                width: 150px;
                height: 150px;
            }

            @include hover-focus() {
                cursor: pointer;
                outline: none;
            }
        }

    }

    .video-content {
        height: 100%;

        @include media-breakpoint-down(md) {
            position: relative;
        }

    }

    .video-caption {
        padding: 15px;
        font-size: 14px;
        line-height: 20px;
        font-family: 'Roboto', sans-serif;

        h2 {
            // font-size: 2.25rem;
            font-size: 1.875rem;
            line-height: 1.25;
            margin-bottom: 1em;
            font-weight: 600;
            font-family: 'Open Sans', sans-serif;
        }

        @include media-breakpoint-down(md) {
            display: none;
        }

        @include media-breakpoint-up(md) {
            text-align: left;
            // bottom: 0;
            position: absolute;
            right: 15%;
            left: 5%;
            z-index: 10;
            width: 40%;
            padding-top: 20px;
            padding-bottom: 20px;
            top: 50%;
            transform: translateY(-50%);
        }

        // @include media-breakpoint-up(lg) {
        //     width: 35%;
        // }

        @include media-breakpoint-up(xl) {
            width: 35%;
            left: 10%;
        }
    }
}

.details {
    p {
        margin-bottom: .5rem;
    }

    a {
        color: $shades-grey;

        @include hover-focus() {
            color: $primary;
        }
    }
}



.object-fit {

    @include media-breakpoint-down(md) {
        padding-right: 0;
        padding-left: 0;
    }

    position: relative;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

}

.tooltip-inner {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
}

.block-reassurance {
    .col-md-6 {
        &:not(:last-child) {
            @include media-breakpoint-up(lg) {
                border-right: 1px solid $secondary;
            }


            @include media-breakpoint-down(md) {
                border-bottom: 1px solid $secondary;
            }

            &:nth-child(-n+2) {

                @include media-breakpoint-down(lg) {
                    border-bottom: 1px solid $secondary;
                }
            }

            &:nth-child(odd) {

                @include media-breakpoint-down(lg) {
                    border-right: 1px solid $secondary;
                }
            }

        }
    }
}

.block-reassurance-item {
    padding: 30px 15px;
    font-size: 14px;
    color: $grey;
    align-items: center;

    span {
        color: $primary;
        font-weight: 600;
    }

    &:not(:last-child) {
        border-right: 1px solid;
    }
}


.card {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    font-size: 12px;
    color: $davys-grey;

    a {
        color: $davys-grey;
        text-decoration: none;

        @include hover-focus() {
            color: $primary;
        }
    }
}

.card-heading {
    background-color: $primary;
    color: $white;
    text-align: center;
    padding: .75rem .25rem;

    h4 {
        font-weight: normal;
        font-size: 15px;
        margin-bottom: 0;
    }
}

.card-body {
    address {
        margin-bottom: 0;
    }

    .row {
        [class*="col"] {
            margin-bottom: .5rem;
        }

        .col-4 {
            color: $primary;
        }
    }
}



form {
    [class*="col"] {
        margin-bottom: 1rem;

        .form-floating {
            margin-bottom: 0;
        }
    }
}

.form-control {
    border: none;
    border-bottom: 1px solid $light-grey;
    &.is-invalid {
        background-size: 1rem;
    }
}

.form-select {
    border: none;
    border-bottom: 1px solid $light-grey;
}

.form-control-grey {
    background: $light-grey;
    padding: 0.25rem 1rem;
    text-align: center;
    border-radius: 3px;
}

.form-floating>.form-select {
    // padding: .25rem 0.75rem;
    padding: 1rem 0;
    // height: 2.5rem;
    height: calc(3.5rem + 2px);
    background-position: right 0.75rem center;
    font-weight: 400;
    color: $black;
    font-size: 14px;
    border: none;
    border-bottom: 1px solid $light-grey;
}

.form-floating>.form-select~label {
    // padding: .625rem 0.75rem;
    padding: .625rem 0;
    transform: scale(0.85) translateY(-1.5rem) translateX(0.15rem);
    // font-weight: 600;
    // background-color: $white;
    // font-size: 10px;
}

.input-group .btn {
    padding: 0.375rem 0.75rem;
    // padding-left: 0;
    // border-bottom: 1px solid $light-grey;
}

// breadcrumbs

.breadcrumb {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    @include media-breakpoint-down(md) {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: 2.75rem;
    }
}

.breadcrumb-item {
    a {
        color: $shades-grey;
        // font-size: 12px;


        @include hover-focus() {
            color: $primary;
        }
    }
}

.nav-tabs {
    border-bottom: 1px solid #FFE1BF;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    padding-bottom: 2px;

    .nav-link {
        font-size: 18px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: normal;
        padding: 1.875rem .75rem;
        color: $grey;
        border-radius: 0;

        @include media-breakpoint-up(xl) {
            padding: 1.875rem 3rem;
        }

        &.active {
            border-bottom: 2px solid $primary;

            @include media-breakpoint-down(sm) {
                background-color: rgba($primary, .1);
                // color: #000;
            }
        }
    }


}

.product-tabs {
    @include media-breakpoint-down(md) {
        background-color: $lighter-orange;
        position: relative;
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-bottom: 1rem;

        &:after {
            width: 100vw;
            height: 100%;
            background: $lighter-orange;
            content: "";
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            position: absolute;
            z-index: -1;
        }
    }
}

.product-tabs {
    .tab-pane {
        @extend .col-md-10;
        margin: 0 auto;
        padding: 2.5rem 0;
        color: $shades-grey;

        ul,
        ol,
        p {
            font-size: 14px;
        }

    }
}



.checked {
    list-style-type: none;
    padding-left: 0;

    li {
        margin-bottom: 1rem;

        &:before {
            content: "\e876";
            font-size: 1.5rem;
            vertical-align: -0.255em;
            transition: all .3s;
            color: $success;
            @extend .material-icons;
            margin-right: 1rem;
        }
    }
}

.dropdown-toggle {
    &:after {
        border: none;
        content: "\e5cf";
        @extend .material-icons;
        font-size: 1rem;
        vertical-align: -0.255em;
        transition: all .3s;
    }

    &[aria-expanded="true"] {
        &:after {
            content: "\e5ce";
        }
    }
}

.px-lg-auto {
    @include media-breakpoint-up(lg) {
        padding-right: calc(var(--bs-gutter-x) / 2) !important;
        padding-left: calc(var(--bs-gutter-x) / 2) !important;
    }
}

.sticky-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
}

.p-mddown-0 {
    @include media-breakpoint-down(md) {
        padding: 0;
    }
}

.p-lgdown-0 {
    @include media-breakpoint-down(lg) {
        padding: 0;
    }
}

.nav-buttons {
    @include media-breakpoint-up(md) {
        background-color: $lighter-orange;
    }

    padding: .375rem;

    .nav-link {
        color: $shades-grey;

        &.active {
            background-color: $white;
            color: $primary;
            box-shadow: 0px 4px 4px rgba(140, 146, 167, 0.1);

        }

    }

    @include media-breakpoint-down(md) {
        .nav-item {
            background-color: $lighter-orange;
            padding: .375rem;
            margin-bottom: .375rem;
            width: 100%;
        }
    }
}

.doc-list {
    --bs-gutter-x: 1.5rem
}

.doc-item {
    margin-bottom: 1.5rem;
    display: flex;
    border: 1px solid $secondary;
    border-radius: 4px;
    color: #676767;
    padding: 1rem;

    svg {
        flex-shrink: 0;
    }

    .doc-content {
        flex-grow: 1;
        margin-left: 1rem;
    }

    h4 {
        font-weight: 600;
        font-size: 14px;
        color: #000;
    }

    small {
        font-size: 12px;
    }

    p {
        margin-bottom: .5rem;
    }

    @include hover-focus() {
        background-color: $secondary;
        color: #676767;
        transition: all .3s;

        svg {
            path {
                fill: #676767;
            }
        }
    }
}

.form-control:disabled {
    opacity: .5;
    @include placeholder {
        opacity: .5;
    }
    + label {
        opacity: .5;
    }
}

.btn-back {
    padding-left: 0;
    padding-right: 0;
    @include hover-focus() {
        color: $primary;
        box-shadow: none;
    }
}

.fade:not(.show) {
    &.active {
        opacity: 1;
    }
}
.md-48 {
    font-size: 48px;
}