.category-description {
    background: #FCFCFC;
    border: 1px solid $light-grey;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 1.5rem;
    font-size: 14px;
    color: #000;
    margin-bottom: 2rem;

    p {
        margin-bottom: 0;

        +p {
            margin-top: 1rem;
        }
    }

    @include media-breakpoint-down(md) {
        &:not(.category-description--long) {
            display: none;
        }
    }
}

.products-selection {
    margin-bottom: 2rem;
    align-items: center;
}

.category-title {
    font-size: 18px;
    color: $shades-grey;
    margin-bottom: 1em;

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }
}

.products-sort-order {
    text-align: right;

    .select-title {
        font-size: 14px;
        display: inline-block;
        padding: .35rem 1.5rem;
        color: $shades-grey;
        cursor: pointer;
        background: #fff;
        border: 1px solid $light-grey;
        border-radius: 3px;
        font-weight: 600;
        height: 100%;
        white-space: nowrap;

        @include media-breakpoint-down(xl) {
            padding: .35rem 0.875rem;
        }

        &:after {
            content: "\e5cf";
            font-size: 1rem;
            vertical-align: -0.255em;
            transition: all .3s;
            color: $shades-grey;
            @extend .material-icons;
            margin-left: .5rem;
        }

        @include media-breakpoint-down(md) {
            background-color: transparent;
            border: none;
            color: $primary;
            width: 100%;
            padding: .35rem 0.625rem;

            &:after {
                color: $primary;
            }
        }
    }

    .dropdown-menu {
        left: auto;
        width: calc(100% - 40px);
        margin: 0;
        background: #fff;
        border: none;
        border-radius: 0;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, .08);

    }

    .select-list {
        display: block;
        padding: 0.625rem 1.25rem;
        color: $shades-grey;
        // color: #232323;
    }
}

.filter-button {
    .btn {
        width: 100%;
        color: $primary;
        font-size: 14px;
        display: inline-block;
        width: 100%;
        padding: 0.625rem;
    }

    @include media-breakpoint-down(md) {
        border-right: 1px solid $warning;
    }
}

.sort-by-row {
    @include media-breakpoint-down(md) {
        margin: 0;
        background-color: $lighter-orange;
        border: 1px solid $warning;

        .col-6 {
            padding: 0;
        }
    }
}

.pagination {
    .page-link {
        font-size: 1rem;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border: none;
    }

    .material-icons {
        font-size: 18px;
        line-height: 30px;
    }

    .page-item {
        +.page-item {
            margin-left: 1rem;
        }

        &:first-child {
            .page-link {
                border-radius: 50%;
            }
        }

        &:last-child {
            .page-link {
                border-radius: 50%;
            }
        }
    }
}



.question-mark {
    padding: 0;

    span {
        font-size: 1rem;
    }
}

.filter-group {

    .question-mark {
        color: $primary;
        margin-right: .25rem;
    }

    .form-check-input {
        border-color: $shades-grey;

        &:checked {
            border-color: $primary;
        }
    }

    .form-check {
        margin-bottom: .5rem;
    }

    .form-control-grey {
        max-width: 88px;
    }
}

.page-category {
    .section__brands-slider {
        .container {
            padding: 0;
        }
    }
}

.search_filters_wrapper .card>*:nth-last-child(2) {
    border-bottom: none;
}

.text-end {
    .form-control {
        margin-left: auto;
    }
}

.brand-products {
    a {
        font-size: .875rem;
    }
}

.brand-item {
    margin-bottom: 3rem;
    display: flex;

    .brand-infos {
        flex-grow: 1;
        margin-left: 1rem;

        img {
            @extend .img-fluid;
            margin-bottom: 1rem;
        }
    }

    a {
        color: $body-color;
    }

    .brand-img {
        height: 185px;
        text-align: center;
        flex-shrink: 0;

        a {
            display: block;
        }

        img {
            max-width: 100%;
            height: auto;
            object-fit: contain;
            max-height: 185px;
        }
    }
    @include media-breakpoint-down(xxl) {
        flex-wrap: wrap;
        .brand-img {
            width: 100%;
        }
        .brand-infos {
            margin-left: 0;
        }
    }
}

.section__brands-slider {
    @include media-breakpoint-down(md) {
        .section-title {
            text-align: center;
        }
    }
}