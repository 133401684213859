$container-max-widths: (
  sm: 540px,
  md: 744px,
  lg: 960px,
  xl: 1160px,
  xxl: 1300px
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
);
$grid-gutter-width: 2.5rem;

// colors
$primary: #F18022;
$secondary: #FEE1C1;
$light-grey: #EFEFEF;
$grey: #656565;
$dim-grey: #616161;
$davys-grey: #4A4A4A;
$shades-grey: #525252;
$silver: #C9C9C9;

$danger: #FE3E01;
$success: #3AA11A;
$info: #F9F8F8;
$warning: #FEE1C1;
$lighter-orange: #FEF7EF;
$text-muted: #A4A4A4;
$floral-white: #FFF8F0;
$cultured: #F4F2F2;

$body-color: $shades-grey;

$font-family-base:  'Open Sans', 'Roboto', sans-serif;
$h1-font-size: 30px;
$h2-font-size: 24px;
$h3-font-size: 20px;
$h4-font-size: 15px;
$h5-font-size: 12px;
$h6-font-size: 10px;
$font-size-base:  .938rem;
// $font-size-base: .875rem;
$headings-font-weight:  600;

//buttons
$btn-font-weight: 600;

//icons
$material-icons-font-path: './../fonts/material/' !default;
// $material-icons-font-size: 24px !default;
// $material-icons-font-display: block !default;

//swiper
$themeColor: $grey !default;

//buttons
$border-radius: 3px;

//forms
$input-padding-y: 1rem;
$input-padding-x: 0;
$input-border-radius: 0;
$input-box-shadow: none;
$input-focus-box-shadow: none;
$input-color: $shades-grey;
$input-border-color: $light-grey;
// $input-disabled-bg: $lighter-orange;
$input-disabled-bg: transparent;

$form-select-padding-y: 1rem;
$form-select-padding-x: 0; 
$form-select-box-shadow: none;
$form-select-focus-box-shadow: none;

//cards
$card-border-radius: 0;
$card-border-color: transparent;
$card-spacer-y: 1.875rem;
$card-spacer-x: 1.875rem;
$card-bg: transparent;

//tooltips
$tooltip-bg: $floral-white;
$tooltip-color: $davys-grey;
$tooltip-border-radius: 5px;
$tooltip-opacity: 1;
$tooltip-padding-y: .875rem;
$tooltip-padding-x: .875rem;

//breadcrumbs
$breadcrumb-active-color: $primary;
$breadcrumb-font-size: 12px;
$breadcrumb-divider: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 9L5 5L1 1' stroke='currentColor' stroke-linecap='round'/%3E%3C/svg%3E%0A");

//tabs

$nav-tabs-link-active-bg: #fff;
$nav-tabs-border-radius: 5px;
// $nav-link-color:     #656565;
$nav-tabs-link-active-color: $grey;
// $nav-link-padding-y: 1.875rem;
$nav-tabs-border-color: transparent;  
$nav-tabs-border-width: 2px;
$nav-tabs-link-active-border-color: transparent;
$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-link-active-bg: transparent;

$pagination-color: $shades-grey;
$pagination-padding-y: 0;
$pagination-padding-x: 0;
$pagination-hover-color:            $primary;
$pagination-hover-bg:               $warning;
$pagination-hover-border-color:     $warning;
$pagination-focus-color:            $primary;
$pagination-focus-bg:               $warning;
$pagination-focus-outline:          0;


// $btn-padding-y: .938rem;
$btn-padding-y: .985rem;
$btn-padding-x: 1.5rem;


$modal-content-border-radius: 3px;
$modal-header-border-color: transparent;