.filter-group {
    border-bottom: 1px solid $light-grey;
    font-size: .938rem;

    .card-header {
        border-bottom: 0;
        background-color: transparent;
        // padding-left: 0;
        // padding-right: 0;
        padding: 1.25rem 0;

        small {
            font-weight: 400;
            font-size: 12px;
            display: block;
            margin-top: .25rem;
        }

        .form-switch {
            float: right;
        }

        .title {
            font-weight: 600;
            font-size: 15px;
            color: $shades-grey;
            margin-bottom: 0;
        }

    }

    &:last-child {
        border-bottom: 0
    }
}

.icon-control {
    float: right;
    font-size: 1rem;

    &:before {
        content: "\e313";
    }
}

a[aria-expanded="true"] {
    .icon-control:before {
        content: "\e316";
    }
}

.range-wrapper {
    @media (min-width: 768px) and (max-width: 1024px) {
        .form-control {
            padding: 0.25rem .5rem;
            font-size: .75rem;
        }
    }
}

.search_filters_wrapper {
    @include media-breakpoint-down(md) {
        opacity: 0;

        &.show {
            opacity: 1;
        }
    }

    .card {
        box-shadow: none;
    }

    .card-body {
        padding: 0;
        padding-bottom: 1.25rem;
        max-height: 200px;
        overflow-y: auto;
    }

    h2 {
        font-size: 18px;
        color: $shades-grey;
        margin-bottom: 0;
    }

    .js-search-toggler {
        background-color: $primary;
        position: absolute;
        left: -70px;
        top: 0;
        border-radius: 0;
        width: 70px;
        height: 70px;
        padding: 0;
        color: #000;

        span {
            font-size: 2rem;
        }
    }

    @include media-breakpoint-down(md) {
        position: fixed;
        bottom: 0;
        left: 100%;
        right: 0;
        z-index: 11;
        // width: 100%;
        background: #fff;
        transition: 0.5s;
        padding: 0 20px;
        width: calc(100% - 70px);
        // top: 82.5px;
        top: 0;

        &.show {
            left: 70px;

            // overflow: visible;
            // overflow-y: scroll;
            // overflow-x: auto;
            .card-footer {
                left: 70px;
            }
        }

        .card {
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            padding-bottom: 7rem;
        }
    }

    .card-footer {
        background-color: transparent;
        position: fixed;
        bottom: 0;
        padding: 0;
        // margin: 0 -20px;
        left: 100%;
        width: calc(100% - 70px);
        transition: 0.5s;

        .btn {
            display: block;
            width: 100%;
            border-radius: 0;
        }

        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}

.lock-body-scroll {
    overflow: hidden !important;
}

.search_filters_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
        margin-left: .5rem;
    }
}

.magnitude {
    // float: right;
    // margin-left: .5rem;
    position: absolute;
    right: 0;
    top: .05rem;
}

.filter-content {
    color: $shades-grey;
    .form-check-label {
        padding-right: 2rem;
        position: relative;
    }    
}

.form-switch {
    .form-check-input {
        height: 20px;
        width: 36px;
    }
}

.with-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex-wrap: wrap;

    .form-switch {
        margin-left: 1rem;
    }

}

.color-checkbox {
    padding-left: 0;
    display: inline-block;
    position: relative;

    .color {
        width: 36px;
        height: 36px;
        border: 2px solid transparent;
        display: inline-block;
    }

    .form-check-input {
        opacity: 0;
        margin: 0;
        margin-left: 0;
        position: absolute;
        @include absolute-center;

        &:checked {
            ~.color {
                border-color: $primary;
            }
        }
    }
}

.range-slider {
    margin-bottom: 1rem;
}


.noUi-connect {
    background: $primary !important;
    height: 2px !important;
}

.noUi-handle {
    cursor: pointer !important;
    border-radius: 50% !important;
    width: 10px !important;
    height: 10px !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: #fff !important;
    border: 2px solid $primary !important;
    top: -4px !important;
    right: -5px !important;
}

.noUi-target {
    background-color: transparent !important;
    border: none !important;
}

.noUi-horizontal {
    height: 2px !important;
    box-shadow: none !important;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.noUi-handle:before,
.noUi-handle:after {
    display: none !important;
}

.noUi-connects {
    background: $secondary;
}