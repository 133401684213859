.section-about,
.page-cms {
    color: $shades-grey;
    h1 {        
        font-weight: 600;
        font-size: 24px;         
    }
    h2 {
        font-weight: 600;
        font-size: 20px; 
    }
    h3 {
        font-size: 1rem;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $shades-grey;
        margin-bottom: 1.5em;
        margin-top: .5em;
    }
    table {
        @extend .table;
    }
}