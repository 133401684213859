@mixin hover-focus($self: false) {
  @if $self {

      &,
      &:hover,
      &:active,
      &:focus {
          @content;
      }
  }

  @else {

      &:hover,
      &:active,
      &:focus {
          @content;
      }
  }
}

@mixin overlay() {
  position: relative;

  &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(90.01deg, rgba(66, 66, 66, 0.427) 0.01%, rgba(0, 0, 0, 0) 99.99%);
      transition: all 1s;
      -webkit-transition: all 1s;
  }

  >* {
      z-index: 100;
      // position: relative;
  }
}

@mixin absolute-center {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

@mixin placeholder {
  &.placeholder {
      @content
  }

  &:-moz-placeholder {
      @content
  }

  &::-webkit-input-placeholder {
      @content
  }
}

@mixin odd() {
  &:nth-child(odd) {
      @content
  }
}

@mixin even() {
  &:nth-child(even) {
      @content
  }
}

@mixin list-style() {
  list-style: none;
  padding-left: 1em;

  li {
      &:before {
          content: "\2022";
          color: $primary;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
      }
  }
}

@mixin line-clamp($numLines: 1, $lineHeight: 1.412) {
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  display: block;
  /* autoprefixer: off */
  display: -webkit-box;
  -webkit-line-clamp: $numLines;
  -webkit-box-orient: vertical;
  max-height: $numLines * $lineHeight + unquote('em');
}