.post-item {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    background-color: $white;
    height: 100%;

    .post-img-wrapper {
        display: inline-block;
        width: 100%;
        height: 185px;
        overflow: hidden;

        img {
            height: 100%;
        }
    }

    h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        color: $black;
    }

    img {
        width: 100%;
        object-fit: cover;
    }

}

.post-excerpt {
    color: $shades-grey;
    @include line-clamp(4, 1.5);
}

.post-body {
    padding: 1.875rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 195px;
}

// .swiper-slide {
//     .post-item {
//         img {
//             width: 100%;
//             height: 185px;
//             overflow: hidden;
//             object-fit: cover;
//         }
//     }
// }

.blog-wrapper {
    .row {
        --bs-gutter-x: 1.25rem;
    }

    .col-md-6 {
        margin-bottom: 1.5rem;
    }

    // .post-body {
    //     display: flex;
    //     flex-direction: column;
    //     justify-content: space-between;
    //     min-height: 195px;
    // }
}

.title_blog {
    font-weight: 600;
    font-size: 1.125rem;
    margin-bottom: 1em;
}

.ybc-blog-tag-a {
    @extend .btn;
    @extend .btn-outline-primary;
    font-weight: normal;
    margin-bottom: .625rem;
    margin-right: .625rem;
    padding: .25rem .625rem;
    font-size: .875rem;

    @include media-breakpoint-down(md) {
        background-color: #fff;
    }
}

.ybc_block_search {
    .btn {
        color: #000;
        border-bottom: 1px solid $light-grey;
    }

    @include media-breakpoint-down(md) {
        .form-control {
            background-color: transparent;
        }
    }
}

.block {
    margin-bottom: 2.5rem;
}

.ybc_block_categories {
    .nav-link {
        color: $shades-grey;
        padding: .92rem 0;
        font-weight: 600;

        &.active {
            color: $primary;
        }

        @include hover-focus {
            color: $primary;
        }
    }

}

.blog_view_all {
    border-bottom: 1px solid $light-grey;
    // &:after {
    //     border: none;
    //     content: "\e5cc";
    //     @extend .material-icons;
    //     font-size: 1rem;
    //     vertical-align: -0.255em;
    //     transition: all .3s;
    //     float: right;

    // }
}

.blog-categories {
    .dropdown-toggle {
        &:after {
            float: right;
        }
    }

    .nav-item {
        border-bottom: 1px solid $light-grey;
    }

    .dropdown-menu.show {
        position: relative!important;
        transform: translate(0px, 0px)!important;
        border: none;
        background: transparent;
       
    }
    .dropdown-item {
        padding: 0.5rem 0;
        white-space: normal;
        @include hover-focus {
            background-color: transparent;
            color: $primary;
        }
    }
}

.blog-expand {
    color: $shades-grey;
    display: block;
    position: relative;

    .icon-control {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        @include media-breakpoint-up(md) {
            display: none;
        }
    }


}

.ybc_block_tag {
    @include media-breakpoint-down(md) {
        margin-bottom: 0;
    }
}


.ybc-navigation-blog-content {
    @include media-breakpoint-down(md) {
        background: $lighter-orange;
        position: relative;
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-bottom: 1rem;

        &:after {
            width: 100vw;
            height: 100%;
            background: $lighter-orange;
            content: "";
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            position: absolute;
            z-index: -1;
        }
    }
}

.blog-extra {
    display: flex;
    flex-wrap: wrap;

    >* {
        margin-bottom: 1.25rem;
        margin-right: 1.875rem;
        display: flex;
        align-items: center;

        .material-icons {
            margin-right: 1rem;
            font-size: 1.25rem;
        }
    }
}

.ybc_image-single {
    margin-bottom: 1.25rem;

    @include media-breakpoint-down(md) {
        margin-left: -1.25rem;
        margin-right: -1.25rem;
    }
}

.ybc-blog-wrapper-content {
    h1 {
        color: #000;
        font-size: 1.5rem;
        line-height: 1.4;
        margin-bottom: 1em;
    }
}

.ybc-blog-tags-social {
    margin-left: auto;
    margin-right: 0;

    @include media-breakpoint-down(md) {
        flex-basis: 100%;
    }

    ul {
        @extend .list-inline;
        margin-bottom: 0;

        li {
            @extend .list-inline-item;
        }
    }
}

.ybc-blog-categories {
    a {
        color: $shades-grey;
        padding: .25rem .625rem;
    }
}

.blog_description {
    p {
        margin-bottom: 2rem;
    }

    h2,
    h3,
    h4 {
        font-size: $font-size-base;
    }

}

.download {
    &:before {
        border: none;
        content: "\f090";
        @extend .material-icons;
        font-size: 1rem;
        vertical-align: -0.255em;
        transition: all .3s;

        margin-right: 0.25rem;
    }
}