.heading-primary {
    color: $primary;
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 1em;
}

.check-options {
    background-color: $lighter-orange;
    border-radius: $border-radius;
    padding: .375rem;
    display: flex;
    flex-wrap: wrap;

    .form-check {
        flex-basis: 0;
        flex-grow: 1;
        text-align: center;
        padding-left: 0;
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
            flex-basis: 50%;
        }
    }

    .form-check-input {
        display: none;

        &:checked[type="radio"] {
            ~label {
                background: #FFFFFF;
                box-shadow: 0px 4px 4px rgba(140, 146, 167, 0.1);
                color: $primary;

                svg {
                    path {
                        fill: $primary;
                    }

                }
            }
        }
    }

    .form-check-label {
        border-color: transparent;
        border-radius: $border-radius;
        padding: .485rem 1rem;
        width: 100%;
        cursor: pointer;
    }
}

.form {
    margin-top: 2rem;
    color: $shades-grey;

    .row {
        --bs-gutter-x: 1.875rem;
    }

    .forgot-password,
    .form-floating {
        margin-bottom: 1.5rem;
    }
}

.form-footer {
    // display: flex;
    // flex-wrap: wrap;
    padding-top: 1.5rem;
    @extend .row;
    // [class*="col"] {
    //     padding-left: var(--bs-gutter-x);
    //     padding-right: var(--bs-gutter-x);
    // }
}

// input:-internal-autofill-selected,
// input:-webkit-autofill {
//     -webkit-box-shadow: 0 0 0px 1000px $lighter-orange inset;
//     background-color: $lighter-orange!important;
//   }
.p-30 {
    padding: 1.875rem;
}

.b-light-grey {
    border: 1px solid $light-grey;
}

.form-floating>.form-select {
    font-weight: 400;
}

.account-right {
    @include media-breakpoint-up(lg) {
        border: 1px solid $light-grey;
        border-left: none;
    }
}

.account-right-content {
    padding: 1.25rem;

    @include media-breakpoint-up(lg) {
        padding: 4.25rem;
    }
}

.address-footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .btn {
        font-weight: 400;
    }
}

.address {
    padding: 2.25rem 0 1.5rem;
    border-bottom: 1px solid $light-grey;

    h4 {
        margin-bottom: 1.5em;
    }
}


.nav-account-wrapper {
    @include media-breakpoint-up(lg) {
        background-color: $lighter-orange;
        border: 1px solid $light-grey;
    }

    @include media-breakpoint-down(lg) {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        h2 {
            display: none;
        }
        .nav {
            display: flex;
            flex-wrap: nowrap;
            white-space: nowrap;
            width: 100%;
            border-bottom: 1px solid $light-grey;
        }

    }
}

.nav-account {
    .nav-link {
        display: flex;
        align-self: center;
        color: $shades-grey;
        font-weight: 600;
        border-bottom: 1px solid transparent;
        padding: 1.45rem 1rem;
        &.active {
            color: $primary;
            @include media-breakpoint-down(lg) {
                border-bottom: 1px solid $primary;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .nav-link {
            padding: 1.45rem 0;
        }
        .nav-item {
            &:not(:last-child) {
                border-bottom: 1px solid $secondary;
            }
        }
    }


}

.action-edit {
    color: $grey;
    text-decoration: none;
    display: flex;
    align-items: center;

    &[aria-expanded="true"] {
        display: none;

        ~.action-cancel {
            display: block;
        }
    }
}

.toggle-password {
    position: absolute;
    top: 3px;
    right: 4px;
    z-index: 9;
    width: 28px;
    height: 30px;
    background: 0;
    border: 0;

    &[type="button"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}

.toggle-password:active,
.toggle-password:focus,
.toggle-password:hover {
    cursor: pointer;
}

.toggle-password:focus {
    outline: none !important;
}

.input-password {
    padding-right: calc(1.5em + .75rem);
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.input-password[type=password] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='18px' viewBox='0 0 24 24' width='18px' fill='%23616573'%3E%3Cpath d='M0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0zm0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z'/%3E%3C/svg%3E");
}

.input-password[type=text] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23616573'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z'/%3E%3C/svg%3E");
}

.btn-social {
    font-weight: normal;
    font-size: 15px;
    // line-height: 20px;
    color: $shades-grey;
    padding-left: 3rem;
    background-size: 1rem;
    background-position: 1rem center;
    background-repeat: no-repeat;
    width: 100%;
    border: 1px solid $light-grey;
    border-radius: 3px;
    text-align: left;

    &.btn-facebook {
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 10C20 4.47563 15.5206 0 10 0C4.47563 0 0 4.47563 0 10C0 14.99 3.65562 19.1275 8.4375 19.8769V12.8912H5.89813V10.0006H8.4375V7.79625C8.4375 5.29063 9.92813 3.90687 12.2131 3.90687C13.3075 3.90687 14.4531 4.10188 14.4531 4.10188V6.5625H13.19C11.95 6.5625 11.5625 7.33437 11.5625 8.125V10H14.3356L13.89 12.8906H11.5625V19.8762C16.3406 19.1269 20 14.9894 20 9.99937V10Z' fill='%231976D2'/%3E%3C/svg%3E%0A");
    }

    &.btn-google {
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.1706 8.36792H17.4993V8.33334H9.99935V11.6667H14.7089C14.0219 13.6071 12.1756 15 9.99935 15C7.2381 15 4.99935 12.7613 4.99935 10C4.99935 7.23876 7.2381 5.00001 9.99935 5.00001C11.2739 5.00001 12.4335 5.48084 13.3164 6.26625L15.6735 3.90917C14.1852 2.52209 12.1943 1.66667 9.99935 1.66667C5.39727 1.66667 1.66602 5.39792 1.66602 10C1.66602 14.6021 5.39727 18.3333 9.99935 18.3333C14.6014 18.3333 18.3327 14.6021 18.3327 10C18.3327 9.44126 18.2752 8.89584 18.1706 8.36792Z' fill='%23FFC107'/%3E%3Cpath d='M2.62695 6.12125L5.36487 8.12917C6.1057 6.295 7.89987 5 9.99945 5C11.274 5 12.4336 5.48084 13.3165 6.26625L15.6736 3.90917C14.1853 2.52209 12.1945 1.66667 9.99945 1.66667C6.79862 1.66667 4.02279 3.47375 2.62695 6.12125Z' fill='%23FE3E01'/%3E%3Cpath d='M10.0008 18.3334C12.1533 18.3334 14.1091 17.5096 15.5879 16.17L13.0087 13.9875C12.1439 14.6452 11.0872 15.0009 10.0008 15C7.83328 15 5.99286 13.6179 5.29953 11.6892L2.58203 13.7829C3.9612 16.4817 6.76203 18.3334 10.0008 18.3334Z' fill='%234CAF50'/%3E%3Cpath d='M18.1713 8.36791H17.5V8.33333H10V11.6667H14.7096C14.3809 12.5902 13.7889 13.3972 13.0067 13.9879L13.0079 13.9871L15.5871 16.1696C15.4046 16.3354 18.3333 14.1667 18.3333 9.99999C18.3333 9.44124 18.2758 8.89583 18.1713 8.36791Z' fill='%231976D2'/%3E%3C/svg%3E%0A");

    }
    @include media-breakpoint-down(lg) {
        text-align: center;
    }
}

.table-orders {
    // white-space: nowrap;
    color: $shades-grey;

    thead {
        background-color: $lighter-orange;

        >*>* {
            padding: 1rem 1rem;
        }
    }

    tbody {
        font-size: 14px;

        >*>* {
            padding: 1.75rem 1rem;
        }
    }

    .view-order-details-link {
        justify-content: center;
    }

}

.table-order-single {
    td:first-child {
        // @include media-breakpoint-up(md) {
        width: 50%;
        // }
    }

    thead {
        background-color: $light-grey;

        >*>* {
            padding: 1rem 1rem;
            border-bottom: 0;
        }
    }

    tbody {

        // font-size: 14px;
        >*>* {
            padding: 1.75rem 1rem;
            border-bottom: 0;
        }
    }
}

.table-order-detailed {
    // color: #000;

    tbody {
        >*>* {
            border-bottom: 1px solid $light-grey;
        }
    }
}

.card-coupon {
    min-height: 225px;
    margin-bottom: 1rem;
    border-radius: $border-radius;

    .bg-secondary {
        height: 61px;
    }

    svg {
        width: 90px;
        height: 25px;
    }

    >* {
        padding: 1rem;
    }

    > :last-child {
        margin-top: 50px;
       
    }
}

.coupon-item {
    border-bottom: 1px solid $light-grey;
    padding: 2rem 0 1rem;
    
    @include media-breakpoint-up(lg) {
        margin: 0;
    }

    > :first-child {
        @include media-breakpoint-up(lg) {
            padding-left: 0;
        }
    }
    p {
        margin-bottom: 1.5rem;
    }
}

.link-pdf {
    color: $primary;
    padding: 0;
    font-size: .875rem;
    display: block;
}

.ps-alert-success {
    @extend .list-unstyled;
    > li {
        @extend .alert;
        @extend .alert-success;
        p {
            margin-bottom: 0;
        }
    }
    svg {
        width: 1.5rem;
        height: 1.5rem;
    }
}